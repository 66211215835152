import React, { useState } from 'react';
import Container from '@mui/material/Container';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2'; 
import InputAdornment from '@mui/material/InputAdornment';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grow from '@mui/material/Grow';
import { ThemeProvider, createTheme, useTheme } from '@mui/material/styles';
import { useSpring, animated} from 'react-spring'
import './App.css';
import { green } from '@mui/material/colors';
import { Analytics } from "@vercel/analytics/react"

function App() {
  const theme = createTheme({
    // palette: {
    //   primary: {
    //     main: '#8AEA92'
    //   },
    //   secondary: {
    //     main: '#80ADA0'
    //   }
    // }
  });

  const [income, setIncome] = useState(0);
  const [deduction, setDeduction] = useState(0);
  const [taxPercentage, setTaxPercentage] = useState(0);
  const [pensionPercentageSelf, setPensionPercentageSelf] = useState(0);
  const [postTaxIncome, setPostTaxIncome] = useState(0);
  const [useMediaTax, setUseMediaTax] = useState(false);
  const [showResult, setShowResult] = useState(false);

  const Calculate = (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();

    if(income == 0) {
      setPostTaxIncome(0);
      return;
    }
    var d:number = deduction;

    if(deduction > income) {
      d = income;
    }

    var pension:number = income * (pensionPercentageSelf / 100);
    var atp:number = 99;
    var am:number = income * 0.08;
    var mediaTax:number = useMediaTax ? 266.67 : 0;
    var result:number = (income + mediaTax - pension - atp - am - d) * ((100 - taxPercentage) / 100) - mediaTax + d;

    setPostTaxIncome(result < 0 ? 0 : result);
    setShowResult(true);
  }

  const AnimatedNumber = (n: number) => {
    const { number } = useSpring({
      from: { number: 0 },
      number: n,
      delay: 100,
      config: { mass: 1, tension: 22, friction: 10 }      
    });
    return <animated.div className={'result-number'}>{number.to((n) => n.toFixed(0))}</animated.div>
  }

  return (
    <ThemeProvider theme={theme}>   
      <Container maxWidth='sm' sx={{ mt: 10 }}>
        <h1>Regn ud, hvor meget du får udbetalt</h1>
        <form onSubmit={Calculate}>
          <Grid container spacing={4}>
            <Grid xs={12}>

              <TextField 
                type='number'
                label='Løn før skat'
                variant='outlined' 
                sx={{ mt: 2 }}
                fullWidth
                InputProps={{
                  endAdornment: 
                    <InputAdornment position="end" >
                      <span className='input-adorment'>kr.</span>
                    </InputAdornment>,
                    className: 'input-label'
                }}
                InputLabelProps={{
                  className: 'input-label'
                }}
                onChange={e => setIncome(parseInt(e.target.value))}
              />

              <TextField 
                type='number'
                label='Fradrag'
                variant='outlined' 
                sx={{ mt: 2 }}
                fullWidth
                InputProps={{
                  endAdornment: 
                    <InputAdornment position="end" >
                      <span className='input-adorment'>kr.</span>
                    </InputAdornment>,
                    className: 'input-label'
                }}
                InputLabelProps={{
                  className: 'input-label'
                }}
                onChange={e => setDeduction(parseInt(e.target.value))}
              />

              <TextField 
                type='number'
                label='Trækprocent'
                variant='outlined' 
                sx={{ mt: 2 }}
                fullWidth
                InputProps={{
                  endAdornment: 
                    <InputAdornment position="end" >
                      <span className='input-adorment'>%</span>
                    </InputAdornment>,
                    className: 'input-label'
                }}
                InputLabelProps={{
                  className: 'input-label'
                }}
                onChange={e => setTaxPercentage(parseInt(e.target.value))}
              />

              <TextField 
                type='number'
                label='Egen pensionsindbetaling i procent'
                variant='outlined' 
                sx={{ mt: 2 }}
                fullWidth
                InputProps={{
                  endAdornment: 
                    <InputAdornment position="end" >
                      <span className='input-adorment'>%</span>
                    </InputAdornment>,
                    className: 'input-label'
                }}
                InputLabelProps={{
                  className: 'input-label'
                }}
                onChange={e => setPensionPercentageSelf(parseInt(e.target.value))}
              />

              <FormControlLabel 
                control={
                  <Switch 
                    checked={useMediaTax}
                    onChange={e => 
                      setUseMediaTax(!useMediaTax)
                    }                   
                  />
                } 
                label="Fri telefon, computer eller internet"
                sx={{ mt: 2 }} 
              />

              <Button 
                variant='contained' 
                type='submit'
                size='large'
                fullWidth
                sx={{ mt: 5 }}>
                  Beregn
              </Button>
              
            </Grid>     
          </Grid>
        </form>

        <Grow in={showResult}>
          <div className='center-text'>
            <h2>Du får udbetalt:</h2>
            {AnimatedNumber(postTaxIncome)}
          </div>
        </Grow>
		<Analytics/>
      </Container>
    </ThemeProvider>
  );
}

export default App;
